import React, { useContext, useState, useEffect } from 'react';
import * as DatabaseService from '../services/DatabaseService';

const AuthContext = React.createContext();

export function useAuth(){
    return useContext(AuthContext)
}

export function AuthProvider({ children }){

    const [currentUser, setCurrentUser] = useState("")
    const [currentRole, setCurrentRole] = useState("")
    const [currentUserId, setUserId] = useState("")
    const [currentRoleId, setRoleId] = useState("")

    const [loading, setLoading] = useState(true)

    //const { currentAudio, setCurrentAudio } = useState("")

    const getSession = () => {
        DatabaseService.checkSession().then((result) => {
            if(result.loggedIn){
                setCurrentUser(result.user.rows[0].username)
                setCurrentRole(result.user.rows[0].role_name)
                setUserId(result.user.rows[0].id)
                setRoleId(result.user.rows[0].user_role_id)
            }
            setLoading(false)
        });
    }

    /*const getSession = () => {
        let user = localStorage.getItem('user');
        if (user) {
            let result = JSON.parse(user);
            setCurrentUser(result.user.rows[0].username)
            setCurrentRole(result.user.rows[0].role_name)
            setUserId(result.user.rows[0].id)
            setLoading(false)
        } else {
            DatabaseService.checkSession().then((result) => {
                if(result.loggedIn){
                    setCurrentUser(result.user.rows[0].username)
                    setCurrentRole(result.user.rows[0].role_name)
                    setUserId(result.user.rows[0].id)
                    localStorage.setItem('user', JSON.stringify(result));
                }
                setLoading(false)
            });
        }
    }*/

    /*function signup(email, password){
        return auth.createUserWithEmailAndPassword(email, password)
    }*/

    function signup(data){
        return DatabaseService.registerUser(data)
    }

    /*function login(email, password){
        return auth.signInWithEmailAndPassword(email, password)
    }*/

    function login(data){
        return DatabaseService.authenticate(data);
    }

    function generateLoginOTP(method) {
        return DatabaseService.generateLoginOTP(method);
    }

    function verifyLoginOTP(otp, method, isBackupCode) {
        return DatabaseService.verifyLoginOTP(otp, method, isBackupCode);
    }

    /* function logout(){
        auth.signOut()
    }*/

    function logout(){
        //auth.signOut()
        setCurrentUser("");
        return DatabaseService.logout();
    }

    /*function logout(){
        setCurrentUser("");
        localStorage.removeItem('user');
        return DatabaseService.logout();
    }*/

    function resetPassword(email){
        //return auth.sendPasswordResetEmail(email)
    }

    function updateEmail(email){
        //return currentUser.updateEmail(email)
    }

    function updatePassword(password){
        //return currentUser.updatePassword(password)
    }

    /*function setCurrentAudio_func(audio){
        //setCurrentAudio("");
        console.log("setCurrentAudio_func")
        console.log(audio)
    }*/

    useEffect(() => {
        getSession();
    })

    const value = {
        currentUser,
        currentRole,
        currentUserId,
        currentRoleId,
        login,
        generateLoginOTP,
        verifyLoginOTP,
        signup,
        logout,
        //resetPassword,
        //updateEmail,
        //updatePassword
        getSession, 
        //currentAudio,
        //setCurrentAudio_func
    }

    return (
        <AuthContext.Provider value={value}>
            { !loading && children }
            {/* if we're not loading we render out the children otherwise we don't want to render out the children */}
        </AuthContext.Provider>
    )
}