import React, { useEffect } from 'react';
import MuiDrawer from '../../components/MuiDrawer';
import PageNotFoundPage from '../PageNotFound/PageNotFound';
import Schwangerschaftserstuntersuchung_Calculation from './Schwangerschaftserstuntersuchung_Calculation';
import Box from '@mui/material/Box';
import { colorName } from '../../components/colors/Colors';

import { DIAGNOSE_GRAVIDITAET_FACTOR_MAPPING } from './constants/Factor_Map/DIAGNOSE_GRAVIDITAET_FACTOR_MAPPING';

import { TERMIN2_FACTOR_MAPPING } from './constants/Factor_Map/TERMIN2_FACTOR_MAPPING'; //Default Factor Mappging for Schwangerschaftserstuntersuchung
                                                                                        //This will be used for Termin 2                                                                                        

import { TERMIN3_FACTOR_MAPPING } from './constants/Factor_Map/TERMIN3_FACTOR_MAPPING'; //Default Factor Mappging for Schwangerschaftserstuntersuchung
import { TERMIN4_FACTOR_MAPPING } from './constants/Factor_Map/TERMIN4_FACTOR_MAPPING';
import { TERMIN5_FACTOR_MAPPING } from './constants/Factor_Map/TERMIN5_FACTOR_MAPPING';
import { TERMIN6_FACTOR_MAPPING } from './constants/Factor_Map/TERMIN6_FACTOR_MAPPING';
import { TERMIN7_FACTOR_MAPPING } from './constants/Factor_Map/TERMIN7_FACTOR_MAPPING';
import { TERMIN8_FACTOR_MAPPING } from './constants/Factor_Map/TERMIN8_FACTOR_MAPPING';
import { TERMIN9_FACTOR_MAPPING } from './constants/Factor_Map/TERMIN9_FACTOR_MAPPING';
import { TERMIN10_FACTOR_MAPPING } from './constants/Factor_Map/TERMIN10_FACTOR_MAPPING';
import { TERMIN11_FACTOR_MAPPING } from './constants/Factor_Map/TERMIN11_FACTOR_MAPPING';
import { TERMIN12_FACTOR_MAPPING } from './constants/Factor_Map/TERMIN12_FACTOR_MAPPING';
import { UEBERTRAGUNGSTERMINE_FACTOR_MAPPING } from './constants/Factor_Map/UEBERTRAGUNGSTERMINE_FACTOR_MAPPING';

import { GOAE_PAGE_TYPES } from '../../constants/goaePageTypeList';

export default function Schwangerschaftserstuntersuchung_Main(props) {

  const { GOAE_PAGE_TYPE_ID } = props;

  useEffect(() => {
          window.scrollTo(0, 0)
  }, [])

  const renderPage = () => {
    switch(GOAE_PAGE_TYPE_ID) {
      case GOAE_PAGE_TYPES.DIAGNOSE_GRAVIDITAET.id :
        return <Schwangerschaftserstuntersuchung_Calculation 
                  factorMapping={DIAGNOSE_GRAVIDITAET_FACTOR_MAPPING} 
                  kasuistikenTitle="Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor bei der 1. Diagnose (Gravidität möglich) ein:"
                  enableRightTable_H_and_H1_Calculation={true}
                />;
      case GOAE_PAGE_TYPES.TERMIN_2.id :
        return <Schwangerschaftserstuntersuchung_Calculation 
                  factorMapping={TERMIN2_FACTOR_MAPPING} 
                  kasuistikenTitle="Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor beim 2. Termin (9+0 - 9+6. SSW) ein:"
                />;
      case GOAE_PAGE_TYPES.TERMIN_3.id :
        return <Schwangerschaftserstuntersuchung_Calculation 
                  factorMapping={TERMIN3_FACTOR_MAPPING}
                  kasuistikenTitle="Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor beim 3. Termin (12+0 - 13+3 SSW) ein:" 
               />;
      case GOAE_PAGE_TYPES.TERMIN_4.id :
        return <Schwangerschaftserstuntersuchung_Calculation 
                  factorMapping={TERMIN4_FACTOR_MAPPING}
                  kasuistikenTitle="Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor beim 4. Termin (16+0 - 17 +3 SSW) ein:"
                />;
      case GOAE_PAGE_TYPES.TERMIN_5.id :
        return <Schwangerschaftserstuntersuchung_Calculation 
                  factorMapping={TERMIN5_FACTOR_MAPPING}
                  kasuistikenTitle="Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor beim 5. Termin (20+0 - 21+3 SSW) ein:"
                />;
      case GOAE_PAGE_TYPES.TERMIN_6.id :
        return <Schwangerschaftserstuntersuchung_Calculation 
                  factorMapping={TERMIN6_FACTOR_MAPPING}
                  kasuistikenTitle="Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor beim 6. Termin (24+0 - 25+3 SSW) ein:"
                />;
      case GOAE_PAGE_TYPES.TERMIN_7.id :
        return <Schwangerschaftserstuntersuchung_Calculation 
                  factorMapping={TERMIN7_FACTOR_MAPPING}
                  kasuistikenTitle="Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor beim 7. Termin (28+0 - 29+3 SSW) ein:"
                />;
      case GOAE_PAGE_TYPES.TERMIN_8.id :
        return <Schwangerschaftserstuntersuchung_Calculation 
                  factorMapping={TERMIN8_FACTOR_MAPPING}
                  kasuistikenTitle="Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor beim 8. Termin (32+0 - 33+3 SSW) ein:"
                />;
      case GOAE_PAGE_TYPES.TERMIN_9.id :
        return <Schwangerschaftserstuntersuchung_Calculation 
                  factorMapping={TERMIN9_FACTOR_MAPPING}
                  kasuistikenTitle="Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor beim 9. Termin (34+0 - 35+3 SSW) ein:"
                />;
      case GOAE_PAGE_TYPES.TERMIN_10.id :
        return <Schwangerschaftserstuntersuchung_Calculation 
                  factorMapping={TERMIN10_FACTOR_MAPPING}
                  kasuistikenTitle="Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor beim 10. Termin (36+0 - 37+3 SSW) ein:"
                />;
      case GOAE_PAGE_TYPES.TERMIN_11.id :
        return <Schwangerschaftserstuntersuchung_Calculation 
                  factorMapping={TERMIN11_FACTOR_MAPPING}
                  kasuistikenTitle="Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor beim 11. Termin (38+0 - 39+3 SSW) ein:"
                />;
      case GOAE_PAGE_TYPES.TERMIN_12.id :
        return <Schwangerschaftserstuntersuchung_Calculation 
                  factorMapping={TERMIN12_FACTOR_MAPPING}
                  kasuistikenTitle="Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor beim 12. Termin am ET +/-1 Tag ein:"
                />;
      case GOAE_PAGE_TYPES.UEBERTRAGUNGSTERMINE.id :
        return <Schwangerschaftserstuntersuchung_Calculation 
                  factorMapping={UEBERTRAGUNGSTERMINE_FACTOR_MAPPING}
                  kasuistikenTitle="Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor beim Übertragungstermin ein:"
                />;
      default:
        return <PageNotFoundPage />;
    }
  };

  return (
    <Box sx={{backgroundColor: colorName.gray, minHeight: '100vh' }}>
            <MuiDrawer>
              { renderPage() }
            </MuiDrawer>
    </Box>
  );
}