import { Grid, InputAdornment, Typography} from '@mui/material';

import React, { useState, useEffect } from 'react';

import Controls from '../components/controls/Controls';

//import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircle';

import LockOutlinedIcon from '@mui/icons-material/Lock';

////import {AccountCircleOutlinedIcon} from '@mui/icons/AccountCircleOutlined';
//import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import LoginSignupContainer from '../components/Template/LoginSignup';

/* userHistory -> useNavigate in ver 6 of react-router-dom*/
//import { useHistory } from 'react-router-dom'; 
import { Link, useNavigate } from 'react-router-dom';

import { useAuth } from '../contexts/AuthContext';
import OtpForm from '../components/OtpForm';
import { fetchUserConfiguredMethods } from '../services/DatabaseService';

export default function Login() {
    const [step, setStep] = useState('login');

    const { login, currentUser, getSession, verifyLoginOTP, generateLoginOTP } = useAuth();

    const [loginData, setLoginData] = useState({username: '', password: ''});

    const [errorLogin, setErrorLogin] = useState({});

    const [enableValidation, setEnableValidation] = useState(false);

    const [loginButtonDisabled, setLoginButtonDisabled] = useState(false);

    const [alertLogin, setAlertLogin] = useState({text: '', appear: false, severity: ''});

    const [backupCode, setBackupCode] = useState('');
    const [alertOtp, setAlertOtp] = useState({text: '', appear: false, severity: ''});

    // 2FA method selection
    const [configuredMethods, setConfiguredMethods] = useState([]);
    const [selectedMethod, setSelectedMethod] = useState('app')
    const methods = [
        { title: 'Authentifizierungs-Apps', id: 'app' },
        { title: 'SMS', id: 'sms' },
        // { title: 'Email', id: 'email' }
    ];

    //const history = useHistory();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser) {
            navigate('/');
        }

    }, [currentUser, navigate]);

    function changeLogInData(e){
        setLoginData({...loginData, [e.target.name]:e.target.value});
    }

    async function onLogIn(){
        setEnableValidation(true);

        try{
            if(!validation(loginData, true)){

                setLoginButtonDisabled(true);

                await login(loginData).then(r => r.json()).then(async result => {

                    if(result.authentication){

                        if (result.needVerification) {
                            await fetchConfiguredMethods();
                            setStep('select-2fa-method');
                        } else {
                            setAlertLogin({text: result.message, appear: true, severity: 'success'});
                            getSession();
                            setTimeout(()=>{
                                navigate('/plattform');
                            }, 1000);
                        }

                    }else{
                        setAlertLogin({text: result.message, appear: true, severity: 'error'});

                        setLoginButtonDisabled(false);
                    }
                })
            }
        }catch (error){
            console.error(error);
        }
    }

    const fetchConfiguredMethods = async () => {
        const fetchedMethods = await fetchUserConfiguredMethods();
        setConfiguredMethods(fetchedMethods);
        setSelectedMethod(fetchedMethods[0].method);
    };

    const handleSelectMethod = async (event) => {
        if (selectedMethod === 'sms') {
            await generateLoginOTP(selectedMethod);
        }
        setStep('otp');
    }

    const handleCompleteOtp = async (otp) => {
        const isBackupCode = step === 'backup-codes';
        const json = await verifyLoginOTP(otp, selectedMethod, isBackupCode).then(r => r.json());
        if (json.verified) {
            setAlertOtp({text: json.message, appear: true, severity: 'success'});
            getSession();
            setTimeout(()=>{
                navigate('/');
            }, 1000);
        } else {
            const msg = json.message || 'Ungültiger Einmal-Code';
            setAlertOtp({text: msg, appear: true, severity: 'error'});
        }
    }

    function onLoginValidation(){
        validation(loginData, enableValidation);
    }

    function validation(loginData, validate){
        let error = { username: "", password: "" }

        if (validate){
            error.username = (loginData.username === "") ? "Benutzername erforderlich" : "";

            error.password = (loginData.password === "") ? "Eingabe des Passworts ist erforderlich!": "";
        }

        setErrorLogin(error);

        if(error.username === "" && error.password === ""){
            return false;
        }else{
            return true;
        }
    }

    return (
        <>
            <LoginSignupContainer>
                {
                    /*(currentUser) ? history.push('/') : ''*/
                    //(currentUser) ? navigate('/') : ''
                }
                {
                    (step === 'login') &&
                    (<Grid container item spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" component="div" align="center">
                                Anmelden
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controls.Input
                                    label="Benutzername oder E-Mail-Adresse"
                                    name="username"
                                    onChange={changeLogInData}
                                    autoComplete="off"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircleOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={errorLogin.username}
                                    onKeyUp={onLoginValidation}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controls.Input
                                label="Passwort"
                                name="password"
                                onChange={changeLogInData}

                                autoComplete="off"
                                type="password"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockOutlinedIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                error={errorLogin.password}
                                onKeyUp={onLoginValidation}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controls.Button
                                text="Anmelden"
                                color="primaryGreen"
                                onClick={onLogIn}
                                size="large"
                                disabled={loginButtonDisabled}
                            />
                            <Controls.Alert severity={alertLogin.severity} appear={alertLogin.appear} text={alertLogin.text} />
                        </Grid>
                        <Grid container justifyContent={'center'} item xs={12}>
                            <Link to="/passwort-vergessen">Passwort vergessen?</Link>
                        </Grid>
                    </Grid>)
                }
                {
                    (step === 'select-2fa-method') &&
                    (
                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <Controls.RadioGroup
                                    name="2fa-method"
                                    values={selectedMethod}
                                    onChange={(event) => setSelectedMethod(event.target.value)}
                                    label="Authentifizierungsmethode:"
                                    items={methods.filter(m => configuredMethods.some(i => i.method === m.id))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Link to="#" onClick={() => setStep('backup-codes')}>
                                Sie haben keinen Zugriff mehr auf meine 2FA-Methoden? Verwenden Sie einen Backup-Code
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                            <Controls.Button
                                text="Weiter"
                                color="primaryGreen"
                                size="large"
                                onClick={handleSelectMethod}
                            />
                            </Grid>
                        </Grid>
                    )
                }
                {
                    (step === 'otp') &&
                    (
                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <OtpForm handleComplete={handleCompleteOtp} />
                            </Grid>
                            <Grid item xs={12}>
                                <Controls.Alert severity={alertOtp.severity} appear={alertOtp.appear} text={alertOtp.text} />
                            </Grid>
                            <Grid item xs={12}>
                                <Link to="#" onClick={() => setStep('backup-codes')}>
                                Sie haben keinen Zugriff mehr auf meine 2FA-Methoden? Verwenden Sie einen Backup-Code
                                </Link>
                            </Grid>
                        </Grid>
                    )
                }
                {
                    (step === 'backup-codes') && (
                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="body1" component="div" align="center">
                                    Geben Sie einen Ihrer Backup-Codes ein
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Controls.Input 
                                    label="Backup-Code"
                                    value={backupCode}
                                    onChange={(e) => setBackupCode(e.target.value)} 
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controls.Alert severity={alertOtp.severity} appear={alertOtp.appear} text={alertOtp.text} />
                            </Grid>
                            <Grid item xs={12}>
                                <Controls.Button
                                    text="Verifizieren"
                                    color="primaryGreen"
                                    size="large"
                                    onClick={() => handleCompleteOtp(backupCode)}
                                />
                            </Grid>
                        </Grid>
                    )
                }
            </LoginSignupContainer>
        </>
    );
}

/*export default function Login1() {

    const { login, currentUser, getSession } = useAuth();

    const [loginData, setLoginData] = useState({username: '', password: ''});

    const [errorLogin, setErrorLogin] = useState({});

    const [enableValidation, setEnableValidation] = useState(false);

    const [loginButtonDisabled, setLoginButtonDisabled] = useState(false);

    const [alertLogin, setAlertLogin] = useState({text: '', appear: false, severity: ''});

    const history = useHistory();

    function changeLogInData(e){
        setLoginData({...loginData, [e.target.name]:e.target.value});
    }

    async function onLogIn(){

        setEnableValidation(true)

        try{
            if(!validation(loginData, true)){

                setLoginButtonDisabled(true);

                await login(loginData).then(r => r.json()).then(result => {

                    if(result.authentication){

                        setAlertLogin({text: result.message, appear: true, severity: 'success'});

                        getSession();

                        setTimeout(()=>{
                            history.push('/');
                        }, 400);

                    }else{
                        setAlertLogin({text: result.message, appear: true, severity: 'error'});

                        setLoginButtonDisabled(false);
                    }
                })
            }
        }catch (error){
            console.error(error);
        }
    }

    function onLoginValidation(){
        validation(loginData, enableValidation);
    }

    function validation(loginData, validate){
        let error = { username: "", password: "" }

        if (validate){
            error.username = (loginData.username === "") ? "Username is required" : "";

            error.password = (loginData.password === "") ? "Password is required": "";
        }

        setErrorLogin(error);

        if(error.username === "" && error.password === ""){
            return false;
        }else{
            return true;
        }
    }

    return (
        <>
          <LoginSignupContainer>

            {
                (currentUser) ? history.push('/') : ''
            }

            <Grid container item spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" component="div" align="center">
                        User Login
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Controls.Input
                        label="Benutzername"
                        name="username"
                        onChange={changeLogInData}
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircleOutlinedIcon />
                                </InputAdornment>
                            ),
                        }}
                        error={errorLogin.username}
                        onKeyUp={onLoginValidation}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controls.Input
                        label="Passwort"
                        name="password"
                        onChange={changeLogInData}

                        autoComplete="off"
                        type="password"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockOutlinedIcon />
                                </InputAdornment>
                            ),
                        }}
                        error={errorLogin.password}
                        onKeyUp={onLoginValidation}
                    />
                </Grid>
                { /* Login Button
                { /*<Grid item xs={12}>
                    <Controls.Button
                        text="LOGIN"
                        color="primary"
                        onClick={onLogIn}
                        size="large"
                        disabled={loginButtonDisabled}
                    />
                    <Controls.Alert severity={alertLogin.severity} appear={alertLogin.appear} text={alertLogin.text} />
                </Grid>
            </Grid>
          </LoginSignupContainer>
        </>
    )

}*/


