import React, { useEffect, useState } from 'react';
import { useSingleUserData } from '../../services/userService';
import * as DatabaseService from '../../services/DatabaseService';
// Table Libraries
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import DialogOutput from '../../components/DialogOutput';
import ViewFormOutput from '../../components/ViewFormOuput';

import {GOAE_Loading_Input} from './components/Loading/LoadingSkeletonLayout';
// Table Libraries

import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import ViewFormOutputStyle from './styles/ViewFormOutputStyle'

import ProfileCard from './components/ProfileCard';

import InputTableStyle from '../../components/styles/InputTableStyle';

import MissingGoaeFeedBack from './components/MissingGoaeFeedBackForm';

import GOÄ_Abrechnungskompass_Calculation_Results from './results/GOÄ_Abrechnungskompass_Calculation_Results';

export default function GOÄ_Abrechnungskompass(props) {

  const { user_input, user_input_privatpatientinnen, view_only_mode, created_at="", pageUserInfo={} } = props;

  const [viewOnlyMode, setViewOnlyMode] = useState(false);

  const [loadingData, setLoadingData] = useState({ hideFirstRow: false, hideSecondRow: false });

  const { userStatus, sessionUserInfo } = useSingleUserData();

  const effectiveUserInfo = Object.keys(pageUserInfo).length === 0 ? sessionUserInfo : pageUserInfo;

  const [patienten, setPatienten] = useState('');

  const [goaeZifferNumbersInput, setGoaeZifferNumbersInput] = useState({});
  const [input_rows, setInput_rows] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [dialogData, setDialogInformation] = React.useState({ title: "", information: "", status: "", dialog_type: "" });

  //console.log("goaeZifferNumbersInput ", goaeZifferNumbersInput);

  let columns = [[], [], [], []];
  
  const useManualAssignment = false; // Set this flag to true for manual assignment, false for dynamic assignment

  //for input_rows ziffer from number and the last is letters

  if (useManualAssignment) {
    // Manually assign specific items to columns
    /*Object.entries(groupedGopNumbers).forEach(([header_no, { header_description, gops }]) => {
      if (['1','2','3','4'].includes(header_no)) {
        columns[0].push({ header_no, header_description, gops }); // Assign to 1st column
      } else if (['5','6','7','8'].includes(header_no)) {
        columns[1].push({ header_no, header_description, gops }); // Assign to 2nd column
      } else if (['9','10','11','12','13','14','15'].includes(header_no)) {
        columns[2].push({ header_no, header_description, gops }); // Assign to 3rd column
      } else if (['16','17','18','19','20'].includes(header_no)) {
        columns[3].push({ header_no, header_description, gops }); // Assign to 4th column
      } else {
        // You can add additional logic here to assign other items to different columns if needed
        //columns[3].push({ header_no, header_description, gops }); // Default to 1st column
      }
    });*/

  } else {
    // Dynamically assign items to columns
    const totalItems = input_rows.length;
    const itemsPerColumn = Math.ceil(totalItems / columns.length);

    let currentColumnIndex = 0;
    let currentItemCount = 0;

    input_rows.forEach((row) => {
      if (currentItemCount >= itemsPerColumn) {
        currentColumnIndex++;
        currentItemCount = 0;
      }
      if (currentColumnIndex >= columns.length) {
        currentColumnIndex = columns.length - 1; // Ensure we don't exceed the number of columns
      }
      columns[currentColumnIndex].push(row);
      currentItemCount++;
    });
  }

  //console.log("columns ", columns);

  const getGOÄ_ZifferAbrechnungskompassInputRowsData = async () => {

    try {
      const JSONData = await DatabaseService.getGOÄ_Abrechnungskompass();
  
      if (JSONData && JSONData.rows) {
        // Sort JSONData.rows
        const sortedRows = JSONData.rows.sort((a, b) => {
          const aIsNumeric = /^\d+$/.test(a.ziffer);
          const bIsNumeric = /^\d+$/.test(b.ziffer);
  
          if (aIsNumeric && bIsNumeric) {
            return parseInt(a.ziffer) - parseInt(b.ziffer);
          } else if (aIsNumeric) {
            return -1;
          } else if (bIsNumeric) {
            return 1;
          } else {
            return a.ziffer.localeCompare(b.ziffer);
          }
        }).filter(row => row.coefficient !== '0');
  
        setInput_rows(sortedRows);
  
        // Only set initial values if gopNumbersInput is empty
        if (Object.keys(goaeZifferNumbersInput).length === 0) {
          const initialGoaeZifferNumbers = sortedRows.reduce((acc, row) => {
            acc[`anzahl_${row.ziffer}`] = '';
            return acc;
          }, {});
          setGoaeZifferNumbersInput(initialGoaeZifferNumbers);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoadingData(false); 
      setLoadingData({ hideFirstRow: true, hideSecondRow: true });
    }

  }

  useEffect(() => {
    getGOÄ_ZifferAbrechnungskompassInputRowsData(); // Fetch initial data
  }, []);

  const handlePatientenChange = (e) => {
    const value = e.target.value;
    
    if (/^\d*$/.test(value)) {
      setPatienten(value);
    }

    setOpen(false);
  };

  const handleGoaeZifferNumberChange = (key, e) => {
    const value = e.target.value;
    
    if (/^\d*$/.test(value)) {
      setGoaeZifferNumbersInput((prevGoaeZifferNumbers) => ({
        ...prevGoaeZifferNumbers,
        [key]: value,
      }));
    }

    setOpen(false);
  };

  const calculate = () => {
    //console.log("goaeZifferNumbersInput ", goaeZifferNumbersInput);
    //console.log("patienten ", patienten);

    setDialogInformation({ title: "", information: "", status: "view", dialog_type: "results" });
    setOpen(true);
  };

  const handleViewForm = (ziffer, description) => {
    setDialogInformation({ title: "", information: description, status: "view", dialog_type: "goae_ziffer_information" });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {

    if(view_only_mode) {
      setViewOnlyMode(true);
      setPatienten(user_input_privatpatientinnen || '');
      setGoaeZifferNumbersInput(user_input);
    }

  }, [view_only_mode, user_input_privatpatientinnen, goaeZifferNumbersInput]);

  return (<>
  <div>

        { 
          !loadingData.hideFirstRow ? <GOAE_Loading_Input loadingData={loadingData} rowType={1} /> : <>
            <Typography variant="h5" component="h1" gutterBottom sx={{color: '#014017'}}>
              Bitte geben Sie die Anzahl Ihrer Privatpatientinnen und die Anzahl der jeweiligen GOÄ-Ziffern an: 
            </Typography>

            <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                      <div>
                        <TextField
                          required
                          margin="dense"
                          id="patienten"
                          label="Privatpatientinnen"
                          name="patienten"
                          type="text"
                          variant="outlined"
                          value={patienten}
                          onChange={handlePatientenChange}
                          fullWidth
                          sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                              WebkitTextFillColor: 'black',
                              color: 'black',
                            },
                          }}
                          InputProps={{
                            readOnly: viewOnlyMode,
                          }}
                          disabled={viewOnlyMode}
                        />
                      </div>
            </div>
          </>
        }

        { 
          !loadingData.hideSecondRow ? <GOAE_Loading_Input loadingData={loadingData} rowType={2} /> : <>
            <InputTableStyle>
              {columns.map((column, columnIndex) => (
                <div key={columnIndex} style={{ flex: 1 }}>
                    <TableContainer component={Paper} className="table-container">
                      <Table size="small" aria-label="simple table" sx={{ border: ' ' }}>
                        <TableHead>
                          <TableRow className="table-head-row">
                            <TableCell width={"50%"} className="table-head-cell">GOÄ-Ziffer</TableCell>
                            <TableCell width={"50%"} className="table-head-cell">Anzahl</TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </TableContainer>
                    <div key={columnIndex} className="content-div">
                      <TableContainer component={Paper} className="table-container">
                        <Table size="small" aria-label="simple table" className="table">
                          <TableBody>
                              {column.map(({ id, ziffer, description }, rowIndex) => (
                                <TableRow
                                  key={id}
                                  className="last-child-cell"
                                >
                                  
                                  <TableCell width={"50%"} className="table-body-cell">
                                    {
                                      (description !== null && description !== '') ?
                                        <>
                                          <Link className="gop-link" onClick={() => { handleViewForm(ziffer, description) }}>{ziffer}</Link>
                                        </> : <>{ziffer}</>
                                    }
                                  </TableCell>

                                  <TableCell width={"50%"} className="table-body-cell">
                                    <TextField
                                      id={`anzahl_${ziffer}`}
                                      type="text"
                                      value={goaeZifferNumbersInput[`anzahl_${ziffer}`] || ''}
                                      onChange={(e) => handleGoaeZifferNumberChange(`anzahl_${ziffer}`, e)}
                                      placeholder="0"
                                      size="small"
                                      variant="filled"
                                      hiddenLabel
                                      className="text-field"
                                      sx={{
                                        '& .MuiInputBase-input.Mui-disabled': {
                                          WebkitTextFillColor: 'black',
                                          color: 'black',
                                        },
                                        width: '100%'
                                      }}
                                      InputProps={{
                                        readOnly: viewOnlyMode,
                                      }}
                                      disabled={viewOnlyMode}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    {columnIndex === columns.length - 1 && (
                      <>
                      <MissingGoaeFeedBack />
                      <br />
                      
                      <div>
                        <ProfileCard onClick={calculate} />
                      </div>
                      </>
                    )}
                </div>
              ))}
            </InputTableStyle>
          </>
        }

        {
          dialogData.dialog_type === "goae_ziffer_information" || dialogData.dialog_type === "results" ? (
            <DialogOutput
              fullScreen={dialogData.dialog_type === "results"}
              open={open}
              dialogData={dialogData}
              handleClose={handleClose}
              backgroundColor={dialogData.dialog_type === "results" ? '#F1F2F4' : undefined}
              output={
                dialogData.dialog_type === "goae_ziffer_information" ? (
                  <ViewFormOutputStyle><ViewFormOutput description={dialogData.information} /></ViewFormOutputStyle>
                ) : (
                  <GOÄ_Abrechnungskompass_Calculation_Results 
                    patienten={patienten} 
                    goaeZifferNumbersInput={goaeZifferNumbersInput} 
                    viewOnlyMode={viewOnlyMode} 
                    userStatus={userStatus.outputGoaeAccess} 
                    userInfo={effectiveUserInfo} 
                    created_at={created_at} 
                  />
                )
              }
              maxWidth={(dialogData.dialog_type === "goae_ziffer_information") ? 'md': 'sm'}
            />
            ) : null
        }
    </div>

  </>)

}