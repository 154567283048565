/**
 * This is used for the required ziffer and factor list on the result table of GOAE_Factor_Table_Result.js
 * This will be used for the row background color in Schwangerschaftserstuntersuchung_Calculation. 
 */

export const TERMIN2_FACTOR_MAPPING = [
    {
        ziffer: "21",
        anzahl: "1",
    },
    {
        ziffer: "23",
        anzahl: "1",
    },
    {
        ziffer: "602",
        anzahl: "1",
    },
    {
        ziffer: "1070",
        anzahl: "1",
    },
    {
        ziffer: "1075",
        anzahl: "1",
    },
    {
        ziffer: "298",
        anzahl: "3",
    },
    {
        ziffer: "415",
        anzahl: "1",
    },
    {
        ziffer: "3508",
        anzahl: "1",
    },
    {
        ziffer: "3509",
        anzahl: "1",
    },
    {
        ziffer: "3511",
        anzahl: "1",
    },
    {
        ziffer: "3532",
        anzahl: "1",
    },
    {
        ziffer: "3736",
        anzahl: "1",
    },
    {
        ziffer: "3714",
        anzahl: "1",
    },
    {
        ziffer: "4711",
        anzahl: "1",
    },
    {
        ziffer: "4506",
        anzahl: "1",
    },
    {
        ziffer: "403",
        anzahl: "1",
    }
];