import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSeparateStyle from '../../styles/TableSeparateStyle'

export function LoadingSkeletonLayout(props) {

    const { firstTable = true } = props;

    return (
        <TableSeparateStyle>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="loading table">
                    <TableHead>
                        <TableRow>
                            {firstTable ? (
                                <>
                                    <TableCell className="TableCell">
                                        <Box sx={{padding:'9px 0'}}>
                                            <Skeleton animation="wave" />
                                        </Box>
                                    </TableCell>
                                    <TableCell className="TableCell">
                                        <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell className="TableCell">
                                        <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell className="TableCell">
                                        <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell className="TableCell">
                                        <Skeleton animation="wave" />
                                    </TableCell>
                                </>
                            ) : (
                                <>
                                    <TableCell className="TableCell" colSpan={2}>
                                        <Box sx={{padding:'9px 0'}}>
                                            <Skeleton animation="wave" />
                                        </Box>
                                    </TableCell>
                                    <TableCell className="TableCell">
                                        <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell className="TableCell">
                                        <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell className="TableCell">
                                        <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell className="TableCell">
                                        <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell className="TableCell">
                                        <Skeleton animation="wave" />
                                    </TableCell>
                                </>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {firstTable ? (
                            Array.from(new Array(10)).map((_, index) => (
                                <TableRow key={`loading-row-${index}`}>
                                    <TableCell width="26.7%" className="TableCell">
                                        <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
                                    </TableCell>
                                    <TableCell width="26.7%" className="TableCell">
                                        <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
                                    </TableCell>
                                    <TableCell width="26.7%" className="TableCell">
                                        <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
                                    </TableCell>
                                    <TableCell width="10%" className="TableCell">
                                        <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell width="10%" className="TableCell">
                                        <Skeleton animation="wave" variant="rectangular" width={40} height={40} />
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            Array.from(new Array(10)).map((_, index) => (
                                <TableRow key={`loading-row-${index}`}>
                                    <TableCell width="10%" className="TableCell">
                                        <Skeleton animation="wave" variant="rectangular" width={40} height={40} />
                                    </TableCell>
                                    <TableCell width="10%" className="TableCell">
                                        <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell width="40%" className="TableCell">
                                        <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell width="10%" className="TableCell">
                                        <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell width="10%" className="TableCell">
                                        <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell width="10%" className="TableCell" sx={{textWrap: 'nowrap'}}>
                                        <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell width="10%" className="TableCell">
                                        <Skeleton animation="wave" variant="rectangular" width={40} height={40} />
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                        {/* Total Row */}
                        <TableRow>
                            {firstTable ? (
                                <>
                                    <TableCell className="TableCell" colSpan={2} sx={{borderRight:"0!important"}}>
                                        <Box sx={{p:'17px'}}></Box>
                                    </TableCell>
                                    <TableCell className="TableCell" sx={{borderLeft:"0!important", borderRight:"0!important"}}>
                                    </TableCell>
                                    <TableCell className="TableCell total" sx={{textWrap: 'nowrap'}}>
                                        <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell className="TableCell" sx={{borderLeft:"0!important"}}>
                                    </TableCell>
                                </>
                            ) : (
                                <>
                                   <TableCell className="TableCell" colSpan={4} sx={{borderRight:"0!important"}}>
                                        <Box sx={{p:'17px'}}></Box>
                                    </TableCell>
                                    <TableCell className="TableCell" sx={{borderLeft:"0!important", borderRight:"0!important"}}>
                                    </TableCell>
                                    <TableCell className="TableCell total" sx={{textWrap: 'nowrap'}}>
                                        <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell className="TableCell" sx={{borderLeft:"0!important"}}>
                                    </TableCell> 
                                </>
                            )}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </TableSeparateStyle>
    );
}