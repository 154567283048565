import React from 'react';
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';

const ScheineChart = (props) => {
  
  const { data, headerTitle = "Scheine Chart", titleBottom = "Total", titleLeft = "Category", backgroundColor = '#761448' } = props;

  /*const formatNumberGerman = (num) => {
    return num.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };*/

  const formatNumberGerman = (num) => {
    return num.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  };

  /*const calculateSize = (value) => {
    // Adjust the multiplier and minimum size as needed
    const size = Math.max(100, value * 10);
    return size;
    };

    const size = calculateSize(data);*/

  return (
    <Paper style={{ height: '500px', padding: '20px', backgroundColor: 'white' }}>
        <Typography variant="h6" component="div" fontWeight={'bold'} textAlign={'center'} sx={{paddingBottom: '20px'}}>
          {headerTitle}
        </Typography>
        <Box sx={{position:'relative', height: 'calc(100% - 57px)'}}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
                >
                <Box
                    sx={{
                    width: 399,
                    height: 300,
                    borderRadius: '40px',
                    backgroundColor: '#006400', 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    padding: '5% 4%',
                    }}
                >
                    <Typography variant="h1" component="div" color="white">
                        {formatNumberGerman(data.scheineCalculateResult)}
                    </Typography>
                    {data.offeneSprechstunde && (
                      <Typography variant="p" component="div" color="white">
                        Ihre Anzahl an offenen Sprechstunden: {data.offeneSprechstunde}
                      </Typography>
                    )}
                </Box>
                </Box>
        </Box>
        

    </Paper>
  );
};

export default ScheineChart;