import BoxChart from "../../../components/charts/kasuistiken/BoxChart";
import { colorName } from "../../../components/colors/Colors";
import germanFormatCurrency from "../../../utils/germanFormatCurrency";

const BoxChartFilter = (props) => {

    const { sumTable1=0, sumTable2=0, boxChartType=1 } = props;

    let data = 0;
    let backgroundColor = colorName.green;
    let headerTitle = "Box Chart";

    switch (boxChartType) {
        case 1:
            data = sumTable1;
            headerTitle = "€-Wert Ihrer Abrechnung";
            backgroundColor = colorName.green;
            break;
        case 2:
            data = sumTable2;
            headerTitle = "€-Wert der optimalen Abrechnung";
            backgroundColor = colorName.blue;
            break;
        case 3:
            data = sumTable2 - sumTable1;
            headerTitle = "Ihr Potenzial";
            backgroundColor = colorName.orange;
            break;
    }

    return (
        <BoxChart data={germanFormatCurrency(data)} headerTitle={headerTitle} backgroundColor={backgroundColor} />
    );

}

export default BoxChartFilter;