import React, { useState, useEffect } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  TextField,
  Box,
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Modal,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { QRCodeCanvas } from 'qrcode.react';
import { fetchUserConfiguredMethods } from '../../../services/DatabaseService';
import verifyPhoneNumber from '../../../utils/verifyPhoneNumber';

export default function TwoFactorAuthenticationConfiguration() {
  const [configuredMethods, setConfiguredMethods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [secret, setSecret] = useState('');
  const [otpAuthUrl, setOtpAuthUrl] = useState('');
  const [otp, setOtp] = useState('');
  const [verifying, setVerifying] = useState(false);
  const [verified, setVerified] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState({
    app: false,
    phone: false,
    sms: false
  });
  const [smsSent, setSmsSent] = useState(false);
  const [backupCodes, setBackupCodes] = useState([]);
  const [backupCodesModalOpen, setBackupCodesModalOpen] = useState(false);

  useEffect(() => {
    fetchConfiguredMethods();
  }, []);

  const fetchConfiguredMethods = async () => {
    const methods = await fetchUserConfiguredMethods();
    setConfiguredMethods(methods);
    setLoading(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setOtp('');
  };

  const handleConfigure = async (method = 'app') => {
    if (method === 'sms' && !verifyPhoneNumber(phoneNumber)) {
      setError({ ...error, phone: 'Bitte geben Sie eine gültige Telefonnummer ein' });
      return;
    } else {
      setError({ ...error, phone: false });
    }

    const response = await fetch('/api/user/2fa/configure', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ method, phone: phoneNumber })
    }).catch((err) => ({
      message: err.response?.data?.message || 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.'
    }));
    const { secret, url, message } = await response.json();

    if (!response.ok) {
      if (method === 'app') {
        setError({ ...error, app: message });
      }
      if (method === 'sms') {
        setError({ ...error, phone: message });
      }
      return;
    }

    if (method === 'app') {
      setSecret(secret);
      setOtpAuthUrl(url);
    }
    if (method === 'sms') {
      setSmsSent(true);
    }
  };

  const handleVerify = async (method = 'app') => {
    setVerifying(true);
    const response = await fetch('/api/user/2fa/configure/verify', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ method, otp })
    });
    const { verified, backupCodes } = await response.json();
    setVerified(verified);
    setVerifying(false);
    if (verified) {
      fetchConfiguredMethods();
      setBackupCodes(backupCodes);
      setBackupCodesModalOpen(true);
    }
  };

  const handleCloseBackupCodesModal = () => {
    setBackupCodesModalOpen(false);
    setBackupCodes([]);
  }

  if (loading) {
    return <CircularProgress />;
  }

  const methods = [
    { title: 'Authenticator App', key: 'app' },
    { title: 'SMS', key: 'sms' },
    // { title: 'Email', key: 'email' }
  ];

  return (
    <Paper elevation={3} sx={{ p: 0, maxWidth: 700 }}>
      <Modal open={backupCodesModalOpen} onClose={handleCloseBackupCodesModal}>
        <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '600px',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              zIndex: 9999,
            }}
          >
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseBackupCodesModal}>
            <CloseIcon />
          </IconButton>
          <Typography variant="body1" component="div" align="center">
          Bitte bewahren Sie diese Backup-Codes an einem sicheren Ort auf. Sie werden benötigt, wenn Sie keinen Zugriff auf Ihre Zwei-Faktor-Authentifizierungsmethoden haben.
          </Typography>
          <Box sx={{ display: 'flex', my: 2, flexDirection: 'column', alignItems: 'center' }}>
            {
              backupCodes.map((code, index) => (
                <Typography key={index} variant="h6" component="div">
                  {code}
                </Typography>
              ))
            }
          </Box>
        </Box>
      </Modal>
      <>
        {methods.map((method) => (
          <ListItem key={method.key} disablePadding>
            <Accordion
              expanded={expanded === method.key}
              onChange={handleChange(method.key)}
              disabled={method.disabled}
              sx={{ width: '100%' }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${method.key}-content`}
                id={`${method.key}-header`}
              >
                <ListItemText primary={method.title} />
                {configuredMethods.find(m => m.method === method.key) && (
                  <Chip label="konfiguriert" color="success" size="small" sx={{ ml: 1 }} />
                )}
              </AccordionSummary>
              <AccordionDetails>
                {method.key === 'app' && (
                  <Box>
                    {!secret ? (
                      <Button
                        variant="contained"
                        onClick={() => handleConfigure('app')}
                      >
                        Authenticator-App konfigurieren
                      </Button>
                    ) : (
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle1" gutterBottom>
                          Scannen Sie diesen QR-Code mit Ihrer Authenticator-App:
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                          <QRCodeCanvas value={otpAuthUrl} size={200} />
                        </Box>
                        <Typography variant="body2" gutterBottom>
                        Oder geben Sie diesen Code manuell in Ihrer Authenticator App ein: {secret}
                        </Typography>
                        <TextField
                          fullWidth
                          label="Geben Sie OTP ein"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          margin="normal"
                        />
                        {error.app && (
                          <Typography color="error" sx={{ mt: 2 }}>
                            {error.app}
                          </Typography>
                        )}
                        <Button
                          variant="contained"
                          onClick={() => handleVerify('app')}
                          disabled={verifying || otp.length !== 6}
                          sx={{ mt: 2 }}
                        >
                          {verifying ? 'Verifizieren...' : 'Verifizieren'}
                        </Button>
                        {verified !== null && (
                          <Typography
                            color={verified ? 'success.main' : 'error.main'}
                            sx={{ mt: 2 }}
                          >
                            {verified ? 'OTP erfolgreich verifiziert!' : 'Falsches OTP'}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                )}
                {method.key === 'sms' && (
                  <Box>
                    {!smsSent ? (
                      <>
                        <TextField
                          fullWidth
                          label="Mobilnummer eingeben"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          margin="normal"
                        />
                        {error.phone && (
                          <Typography color="error" sx={{ mt: 2 }}>
                            {error.phone}
                          </Typography>
                        )}
                        <Button
                          variant="contained"
                          onClick={() => handleConfigure('sms')}
                          fullWidth
                          sx={{ mt: 2 }}
                        >
                          SMS senden
                        </Button>
                      </>
                    ) : (
                      <>
                        <Typography variant="body2" gutterBottom>
                          SMS an {phoneNumber} gesendet. Bitte geben Sie das OTP ein, das Sie erhalten haben:
                        </Typography>
                        <TextField
                          fullWidth
                          label="Geben Sie OTP ein"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          margin="normal"
                          inputProps={{ maxLength: 6 }}
                        />
                        {error.sms && (
                          <Typography color="error" sx={{ mt: 2 }}>
                            {error.sms}
                          </Typography>
                        )}
                        <Button
                          variant="contained"
                          onClick={() => handleVerify('sms')}
                          disabled={verifying || otp.length !== 6}
                          sx={{ mt: 2 }}
                          fullWidth
                        >
                          {verifying ? 'Verifizieren...' : 'Verifizieren'}
                        </Button>
                        {verified !== null && (
                          <Typography
                            color={verified ? 'success.main' : 'error.main'}
                            sx={{ mt: 2, textAlign: 'center' }}
                          >
                            {verified ? 'OTP erfolgreich verifiziert!' : 'Falsches OTP'}
                          </Typography>
                        )}
                      </>
                    )}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          </ListItem>
        ))}
      </>
    </Paper>
  );
}