import React from "react";

import './App.css';

import {createTheme, CssBaseline, ThemeProvider} from '@mui/material/styles';
//import {createMuiTheme, CssBaseline, ThemeProvider} from '@material-ui/core';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

//CONTENTS
import Platform from "../pages/Contents/Platform/Platform";
import Abrechnungskompass from "../pages/Contents/Abrechnungskompass/Abrechnungskompass";
import Coaching_And_Beratung from "../pages/Contents/Coaching_And_Beratung/Coaching_And_Beratung";
import Digitalisierungssupport from "../pages/Contents/Digitalisierungssupport/Digitalisierungssupport";
import Workshops from "../pages/Contents/Workshops/Workshops";
import Mediathek from "../pages/Contents/Mediathek/Mediathek";

import GOÄ_Abrechnungskompass_Content from '../pages/Contents/GOÄ_Abrechnungskompass/GOÄ_Abrechnungskompass_Content';
import GOÄ_Abrechnungskasuistiken_Content from '../pages/Contents/GOÄ_Abrechnungskasuistiken/GOÄ_Abrechnungskasuistiken_Content';
import GOÄ_Abrechnungskasuistiken_Schwangerenmanagement_Content from '../pages/Contents/GOÄ_Abrechnungskasuistiken/Subpages/GOÄ_Abrechnungskasuistiken_Schwangerenmanagement_Content';
//END OF CONTENTS

//GOÄ_Abrechnungskasuistiken Pages
import Schwangerschaftserstuntersuchung_Main from '../pages/GOÄ_Abrechnungskasuistiken_Calculation/Schwangerschaftserstuntersuchung_Main';
import { GOAE_PAGE_TYPES } from "../constants/goaePageTypeList";
//End of GOÄ_Abrechnungskasuistiken Pages

import Extractor from '../pages/Extractor/Extractor';
import EBM_Koeffizienten from '../pages/EBM_Koeffizienten/EBM_Koeffizienten';
import EBM_Abrechnungskompass from '../pages/EBM_Abrechnungskompass_Old/EBM_Abrechnungskompass_Main';
//import EBM_Abrechnungskompass_New from "../pages/EBM_Abrechnungskompass/EBM_Abrechnungskompass_New";
import EBM_Abrechnungskompass_Main from "../pages/EBM_Abrechnungskompass/EBM_Abrechnungskompass_Main";
import EBM_Contents from '../pages/EBM_Abrechnungskompass/contents/EBM_Contents';
import Abrechnungskompass_CSV_Page from '../pages/EBM_Abrechnungskompass/subpages/Abrechnungskompass_CSV_Page/Abrechnungskompass_CSV_Page';
import CONData from '../pages/EBM_Abrechnungskompass/subpages/CONData/CONData';

import MissingGopFeedbackPage from "../pages/EBM_Abrechnungskompass/subpages/MissingGopFeedback/MissingGopFeedbackPage";

import EBM_Abrechnungskompass_Main_Fix from "../pages/EBM_Abrechnungskompass_Fix/EBM_Abrechnungskompass_Main";

import GOÄ_Abrechnungskompass_Main from '../pages/GOÄ_Abrechnungskompass_Calculation/GOÄ_Abrechnungskompass_Calculation_Main';
import GOÄ_Abrechnungskompass_Old from '../pages/GOÄ_Abrechnungskompass_Calculation_Old/GOÄ_Abrechnungskompass_Calculation';
import GOAE_Fachgruppenvergleich_CSV_Page from "../pages/GOÄ_Abrechnungskompass_Calculation/subpages/Fachgruppenvergleich_CSV_Page/Fachgruppenvergleich_CSV_Page";
import MissingGoaeFeedbackPage from "../pages/GOÄ_Abrechnungskompass_Calculation/subpages/MissingGoaeFeedback/MissingGoaeFeedbackPage";

import Compare from '../pages/Compare/Compare';
import EBM_Kunde from '../pages/EBM_Kunde/EBM_Kunde';
import EBM_Berechnungsdaten from '../pages/EBM_Berechnungsdaten/EBM_Berechnungsdaten';
import PDFtoCSV from '../pages/PDFtoCSV/PDFtoCSV';
import PDFtoDOCX from '../pages/PDFtoDOCX/PDFtoDOCX';
import CONtoCSV from '../pages/CONtoCSV/CONtoCSV';
import Users from '../pages/Users/Users';
import Profile from '../pages/Profile/Profile';
import Articles from '../pages/Post/views/Articles';
import Article from '../pages/Post/views/Article';
import PostNew from '../pages/Post/Post_New';
import Posts from '../pages/Post/Posts';
import SubmitFilesMain from '../pages/SubmitFiles/SubmitFilesMain';
import Filemanager from '../pages/Filemanager/Filemanager';
import PageNotFound from '../pages/PageNotFound/PageNotFound';

import Login from '../pages/Login';
import Signup from '../pages/Signup';

import { AuthProvider } from "../contexts/AuthContext";

import PrivateElement from '../components/PrivateRoute';
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";

const theme = createTheme({
  palette: {
    primaryGreen: {
      main: '#12401F',
      contrastText: '#fff',
    }
  },
  typography: {
    fontFamily: [
      'BR Firma Medium',
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiAppBar: {
      root: {
        transform: 'translateZ(0)',
      },
    },
  },
  props: {
    MuiIconButton: {
      disableRipple: true,
    }
  },
});

function App() {

  return (
    <ThemeProvider theme={theme}>
          <BrowserRouter>
              <AuthProvider>
                  <Routes>
                      {/* Private Route to be implemented soon */}
                      <Route path="/plattform" element={<PrivateElement element={<Platform />} />} />
                      <Route path="/plattform/abrechnungskompass" element={<PrivateElement element={<Abrechnungskompass />} />} />
                      <Route path="/plattform/coaching-praxisberatung" element={<PrivateElement element={<Coaching_And_Beratung />} />} />
                      <Route path="/plattform/digitalisierungssupport" element={<PrivateElement element={<Digitalisierungssupport />} />} />
                      <Route path="/plattform/workshop" element={<PrivateElement element={<Workshops />} />} />
                      <Route path="/mediathek" element={<PrivateElement element={<Mediathek />} />} />
                      <Route path="/" element={<Navigate to="/plattform" />} />
                      {/*<Route path="/extractor" element={<PrivateElement element={<Extractor />} />} />*/}
                      <Route path="/ebm-koeffizienten" element={<PrivateElement element={<EBM_Koeffizienten />} />} />
                      <Route path="/ebm-abrechnungskompass_old" element={<PrivateElement element={<EBM_Abrechnungskompass />} />} />

                      <Route path="/ebm-abrechnungskompass" element={<PrivateElement element={<EBM_Contents />} />} />
                      <Route path="/ebm-abrechnungskompass/manuelle-eingabe" element={<PrivateElement element={<EBM_Abrechnungskompass_Main />} />} />
                      <Route path="/ebm-abrechnungskompass/dateimanager" element={<PrivateElement element={<Abrechnungskompass_CSV_Page />} />} />
                      <Route path="/ebm-abrechnungskompass/eingabe-via-con-datei" element={<PrivateElement element={<CONData />} />} />

                      <Route path="/ebm-abrechnungskompass/fehlende-ebm-ziffern" element={<PrivateElement element={<MissingGopFeedbackPage />} />} />

                      <Route path="/ebm-abrechnungskompass-fix" element={<PrivateElement element={<EBM_Abrechnungskompass_Main_Fix />} />} />

                      <Route path="/gebuehrenordnung-fuer-aerzte-abrechnungskompass" element={<PrivateElement element={<GOÄ_Abrechnungskompass_Content />} />} />
                      <Route path="/gebuehrenordnung-fuer-aerzte-abrechnungskompass/fachgruppenvergleich" element={<PrivateElement element={<GOÄ_Abrechnungskompass_Main />} />} />
                      <Route path="/gebuehrenordnung-fuer-aerzte-abrechnungskompass/fachgruppenvergleich/dateimanager" element={<PrivateElement element={<GOAE_Fachgruppenvergleich_CSV_Page />} />} />
                      <Route path="/gebuehrenordnung-fuer-aerzte-abrechnungskompass/fachgruppenvergleich/fehlende-goä-ziffern" element={<PrivateElement element={<MissingGoaeFeedbackPage />} />} />

                      <Route path="/gebuehrenordnung-fuer-aerzte-abrechnungskompass/fachgruppenvergleich_old" element={<PrivateElement element={<GOÄ_Abrechnungskompass_Old />} />} />
                      
                      {/* Abrechnungskasuistiken */}

                      <Route path="/gebuehrenordnung-fuer-aerzte-abrechnungskompass/abrechnungskasuistiken" element={<PrivateElement element={<GOÄ_Abrechnungskasuistiken_Content />} />} />

                      <Route path="/gebuehrenordnung-fuer-aerzte-abrechnungskompass/abrechnungskasuistiken/schwangerenmanagement" element={<PrivateElement element={<GOÄ_Abrechnungskasuistiken_Schwangerenmanagement_Content />} />} />
                      
                      {/* GOAE_PAGE_TYPES.PARENT_PATH is /gebuehrenordnung-fuer-aerzte-abrechnungskompass/abrechnungskasuistiken/schwangerenmanagement/ */}
   
                      {Object.values(GOAE_PAGE_TYPES)
                          .filter((pageType) => pageType.visibility)
                          .map((pageType) => (
                              <Route
                                  key={pageType.id}
                                  path={`${pageType.link_name}`}
                                  element={
                                      <PrivateElement
                                          element={
                                              <Schwangerschaftserstuntersuchung_Main
                                                  GOAE_PAGE_TYPE_ID={pageType.id}
                                              />
                                          }
                                      />
                                  }
                              />
                          ))}
                      
                      {/* End of Abrechnungskasuistiken */}

                      <Route path="/compare" element={<PrivateElement element={<Compare />} />} />
                      <Route path="/ebm-kunde" element={<PrivateElement element={<EBM_Kunde />} />} />
                      <Route path="/ebm-berechnungsdaten" element={<PrivateElement element={<EBM_Berechnungsdaten />} />} />
                      <Route path="/pdftocsv" element={<PrivateElement element={<PDFtoCSV />} />} />
                      <Route path="/pdftodocx" element={<PrivateElement element={<PDFtoDOCX />} />} />
                      <Route path="/contocsv" element={<PrivateElement element={<CONtoCSV />} />} />
                      <Route path="/dokumentenupload" element={<PrivateElement element={<SubmitFilesMain />} />} />
                      <Route path="/dateimanager" element={<PrivateElement element={<Filemanager />} />} />
                      <Route path="/users" element={<PrivateElement element={<Users />} />} />
                      <Route path="/profil" element={<PrivateElement element={<Profile />} />} />
                      <Route path="/articles" element={<PrivateElement element={<Articles />} />} />
                      <Route path="/podcasts" element={<PrivateElement element={<Articles post_type="2" />} />} />
                      <Route path="/videos" element={<PrivateElement element={<Articles post_type="3" />} />} />
                      <Route path="/article/:id" element={<PrivateElement element={<Article />} />} />
                      <Route path="/post-new" element={<PrivateElement element={<PostNew />} />} />
                      <Route path="/post-new/:id" element={<PrivateElement element={<PostNew />} />} />
                      <Route path="/blog/launch-von-gynsuccess" element={<PrivateElement element={<Posts />} />} />

                      {/* Private Route to be implemented soon */}
                      <Route path="/login" element={<Login />} />
                      <Route path="/signup" element={<Signup />} />
                      <Route path="/passwort-vergessen" element={<ForgotPassword />} />
                      <Route path="/passwort-zurücksetzen" element={<ResetPassword />} />
                      <Route element={<PageNotFound />} />
                  </Routes>
              </AuthProvider>
          </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;