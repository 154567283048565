export const TERMIN9_FACTOR_MAPPING = [
    { ziffer: "24", anzahl: "1" },
    { ziffer: "250", anzahl: "1" },
    { ziffer: "298", anzahl: "3" },
    { ziffer: "403", anzahl: "1" },
    { ziffer: "415", anzahl: "1" },
    { ziffer: "420", anzahl: "3" },
    { ziffer: "602", anzahl: "1" },
    { ziffer: "1002", anzahl: "1" },
    { ziffer: "1070", anzahl: "1" },
    { ziffer: "1075", anzahl: "1" },
    { ziffer: "3508", anzahl: "1" },
    { ziffer: "3509", anzahl: "1" },
    { ziffer: "3511", anzahl: "1" },
    { ziffer: "3532", anzahl: "1" },
    { ziffer: "3550", anzahl: "1" },
    { ziffer: "3551", anzahl: "1" },
    { ziffer: "3555", anzahl: "1" },
    { ziffer: "3557", anzahl: "1" },
    { ziffer: "3558", anzahl: "1" },
    { ziffer: "3560", anzahl: "1" },
    { ziffer: "3561", anzahl: "1" },
    { ziffer: "3575", anzahl: "1" },
    { ziffer: "3714", anzahl: "1" },
    { ziffer: "3736", anzahl: "1" },
    { ziffer: "4711", anzahl: "1" },
    { ziffer: "3583H1", anzahl: "1" },
    { ziffer: "3585H1", anzahl: "1" },
    { ziffer: "3587H1", anzahl: "1" },
    { ziffer: "3592H1", anzahl: "1" },
    { ziffer: "3594H1", anzahl: "1" },
    { ziffer: "3595H1", anzahl: "1" }
];