const GOAE_PAGE_TYPES_PARENT_PATH = "/gebuehrenordnung-fuer-aerzte-abrechnungskompass/abrechnungskasuistiken/schwangerenmanagement/";

export const GOAE_PAGE_TYPES = {
    DIAGNOSE_GRAVIDITAET: { 
                            id: 1, 
                            name: "1. Diagnose Gravidität mögl. (6+0 - 6+6 SSW)",
                            link_name: GOAE_PAGE_TYPES_PARENT_PATH + "erste-diagnose-graviditaet-moeglich",
                            visibility: true
                          },
    TERMIN_2: { 
                id: 2, 
                name: "2. Termin (9+0 - 9+6. SSW)",
                link_name: GOAE_PAGE_TYPES_PARENT_PATH + "zweiter-termin",
                visibility: true
              },
    TERMIN_3: { 
                id: 3, 
                name: "3. Termin (12+0 - 13+3 SSW)",
                link_name: GOAE_PAGE_TYPES_PARENT_PATH + "dritter-termin",
                visibility: true
              },
    TERMIN_4: { 
                id: 4, 
                name: "4. Termin (16+0 - 17 +3 SSW)",
                link_name: GOAE_PAGE_TYPES_PARENT_PATH + "vierter-termin",
                visibility: true
              },
    TERMIN_5: { 
                id: 5, 
                name: "5. Termin (20+0 - 21+3 SSW)",
                link_name: GOAE_PAGE_TYPES_PARENT_PATH + "fuenfter-termin",
                visibility: true
              },
    TERMIN_6: { 
                id: 6, 
                name: "6. Termin (24+0 - 25+3 SSW)",
                link_name: GOAE_PAGE_TYPES_PARENT_PATH + "sechster-termin",
                visibility: true
              },
    TERMIN_7: { 
                id: 7, 
                name: "7. Termin (28+0 -  29+3 SSW)",
                link_name: GOAE_PAGE_TYPES_PARENT_PATH + "siebter-termin",
                visibility: true
              },
    TERMIN_8: { 
                id: 8, 
                name: "8. Termin (32+0 - 33+3 SSW)",
                link_name: GOAE_PAGE_TYPES_PARENT_PATH + "achter-termin",
                visibility: true
              },
    TERMIN_9: { 
                id: 9, 
                name: "9. Termin (34+0 - 35+3 SSW)",
                link_name: GOAE_PAGE_TYPES_PARENT_PATH + "neunter-termin",
                visibility: true
              },
    TERMIN_10: { 
                 id: 10, 
                 name: "10. Termin (36+0 - 37+3 SSW)",
                 link_name: GOAE_PAGE_TYPES_PARENT_PATH + "zehnter-termin",
                 visibility: true
                },
    TERMIN_11: { 
                 id: 11, 
                 name: "11. Termin (38+0 - 39+3 SSW)",
                 link_name: GOAE_PAGE_TYPES_PARENT_PATH + "elfter-termin",
                 visibility: true
                },
    TERMIN_12: { 
                 id: 12, 
                 name: "12. Termin am ET +/-1 Tag",
                 link_name: GOAE_PAGE_TYPES_PARENT_PATH + "zwoelfter-termin",
                 visibility: true
                },
    UEBERTRAGUNGSTERMINE: { 
                            id: 13, 
                            name: "Übertragungstermine",
                            link_name: GOAE_PAGE_TYPES_PARENT_PATH + "uebertragungstermine",
                            visibility: true
                          }
};