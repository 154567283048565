import React from 'react';
import { Grid } from '@mui/material';
import BoxChartFilter from '../../chart_filters/BoxChartFilter';

const Charts_Section = (props) => {

    const { sumTable1=0, sumTable2=0 } = props;

    return (
        <>
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <BoxChartFilter sumTable1={sumTable1} sumTable2={sumTable2} boxChartType={1} />
            </Grid>
            <Grid item xs={4}>
                <BoxChartFilter sumTable1={sumTable1} sumTable2={sumTable2} boxChartType={2} />
            </Grid>
            <Grid item xs={4}>
                <BoxChartFilter sumTable1={sumTable1} sumTable2={sumTable2} boxChartType={3} />
            </Grid>
        </Grid>

        </>
    );
};

export default Charts_Section;