import React, { useEffect, useState, useRef } from 'react';
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';

function BoxChart(props) {
    const { data, headerTitle = "Box Chart", backgroundColor = '#761448' } = props;

    const [fontSize, setFontSize] = useState(72); // Start with large size
    const boxRef = useRef(null);
    const textRef = useRef(null);

    const [isLoaded, setIsLoaded] = useState(false);

    const adjustFontSize = () => {
        if (!boxRef.current || !textRef.current) return;
        
        const boxWidth = boxRef.current.offsetWidth;
        const textWidth = textRef.current.offsetWidth;
        const boxHeight = boxRef.current.offsetHeight;
        const textHeight = textRef.current.offsetHeight;
        
        // Calculate scaling factors
        const widthRatio = boxWidth / textWidth;
        const heightRatio = boxHeight / textHeight;
        const ratio = Math.min(widthRatio, heightRatio) * 0.65; // 65% of available space
        
        setFontSize(Math.floor(fontSize * ratio));
    };

    useEffect(() => {
        const handleResize = () => {
            adjustFontSize();
        };

        // Initial adjustment
        adjustFontSize();

        // Add resize listener
        window.addEventListener('resize', handleResize);
        
        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, [data]); // Only re-run if data changes

    useEffect(() => {
        if (fontSize && data) {
            setIsLoaded(true);
        }
    }, [fontSize, data]);

  return (
    <Paper style={{ height: '500px', padding: '20px', backgroundColor: 'white' }}>
        <Typography variant="h6" component="div" fontWeight={'bold'} textAlign={'center'} sx={{paddingBottom: '20px'}}>
          {headerTitle}
        </Typography>
        <Box sx={{position:'relative', height: 'calc(100% - 57px)'}}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <Box
                    ref={boxRef}
                    sx={{
                        width: 399,
                        height: 300,
                        borderRadius: '40px',
                        backgroundColor: backgroundColor, 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        padding: '5% 4%',
                    }}
                >
                    {isLoaded ? (
                        <Typography  
                            ref={textRef}
                            style={{ fontSize: `${fontSize}px` }} component="div" color="white">
                            { data }
                        </Typography>
                    ) : null}
                </Box>
            </Box>
        </Box>
    </Paper>
  );
}

export default BoxChart;