export const Colors = [
                            '#4791db', 
                            '#f44336', 
                            '#607d8b', 
                            '#388e3c', 
                            '#006064', 
                            '#33691e', 
                            '#ff6f00', 
                            '#795548', 
                            '#e91e63', 
                            '#3f51b5'
                        ];

export const colorName = {
    transparent: '#00000000',
    red: '#b2102f',
    orange: '#FF9800',
    lightorange: '#ffd480',
    black: '#000000',
    white: '#ffffff',
    lightred: '#FF7276',
    inherit: 'inherit',
    color_href: '#1976D2',
    green: '#006400',
    lightgreen: '#76B900',
    lightgreen2: '#CAE3B6', //Implemented on ebm results page
    darkred: '#8B0000',
    blue: '#0f3f93',
    buttonBlue: '#1b75b8',
    gray: '#F1F2F4',
    gray2: '#757575',
};