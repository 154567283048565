import { Avatar, Box, Button, Card, CardContent, Typography, Tooltip } from '@mui/material';
import sebastian_zuber_profile_pic from '../../../components/assets/images/template/dr_med_sebastian_zuber_profile_pic.webp';

const ProfileCard = (props) => {
  
    const { onClick, scheineInputError } = props;
  
    return (
      <Card sx={{backgroundColor: '#f0f0f0'}}>
        <CardContent sx={{textAlign: 'center'}}>
  
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1em' }}>
            <Avatar
              alt="Dr. med. Sebastian Zuber"
              src={sebastian_zuber_profile_pic}
              sx={{ width: 160, height: 160 }}
            />
          </Box>
  
          <Typography variant='h5' component="div" sx={{ textAlign: 'center', marginBottom: '1em', color: '#364713', fontWeight: 600 }}>Dr. med. Sebastian Zuber </Typography>
          
          <Tooltip 
            open={scheineInputError}
            title="Bitte geben Sie zuerst die Anzahl der Scheine ein"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: '#d32f2f', // Material UI error red
                  color: '#fff',
                  '& .MuiTooltip-arrow': {
                    color: '#d32f2f'
                  }
                }
              }
            }}
          >
            <Button variant="contained" onClick={()=>{ onClick() }} sx={{textTransform: "none"}}>Jetzt berechnen und individuelle Analyse anfordern</Button>   
          </Tooltip>
        </CardContent>
      </Card>
    );
};

export default ProfileCard;